/**
 * Sentry logs errors occuring in the browser, so we can track problems in
 * our front-end components.
 */

import {init, browserTracingIntegration, captureConsoleIntegration, type BrowserOptions} from '@sentry/browser';

const SentryIntegrations = [
  browserTracingIntegration(),
  captureConsoleIntegration({
    levels: ['warn', 'error'],
  }),
];

const setupSentry = async(options: BrowserOptions) => {
  init({
    integrations: SentryIntegrations,
    ...options,
  });
};

window.setupSentry = setupSentry;

declare global {
  interface Window {
    setupSentry: typeof setupSentry;
  }
}
